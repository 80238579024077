import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"
import {MailIcon, DownloadIcon, LocationMarkerIcon} from '@heroicons/react/outline'

import Layout from "../components/layout"
import Seo from "../components/seo"

import daltenLogo from "../images/dalten_logo.svg"
import emocioLogo from "../images/emocio_logo.png"
import typagLogo from "../images/typag_logo.png"
import dvorarLogo from "../images/logo_dvorak.png"
import refreshLogo from "../images/refresh-logo.svg"
import {Link} from "gatsby";

const IndexPage = () => (
    <Layout>
        <Seo title="Web development"/>
        <div>
            {/* Hero card */}
            <div className="relative">
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"/>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                        <div className="absolute inset-0">
                            <StaticImage src={"../images/cover.jpg"} alt={"Mountains"}
                                         className="h-full w-full object-cover"/>
                            <div className="absolute inset-0 bg-green-700 mix-blend-multiply"/>
                        </div>
                        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                <span className="block text-white">Jan Skurovec</span>
                                <span className="block text-green-200">full stack developer</span>
                            </h1>
                            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-green-200 sm:max-w-3xl">
                                I’m a web developer, developing web applications with PHP, Symfony Framework, React and
                                GraphQl.
                            </p>
                            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                                <div
                                    className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                                    <a
                                        href="#contact"
                                        className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-green-700 bg-white hover:bg-green-50 sm:px-8"
                                    >
                                        Contact
                                    </a>
                                    <Link
                                        to={"/references"}
                                        className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                                    >
                                        References
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Logo cloud */}
            <div className="bg-gray-100">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
                        Trusted by this businesses
                    </p>
                    <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                            <img className="h-12 filter grayscale" src={daltenLogo} alt="DALTEN media"/>
                        </div>
                        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                            <img className="h-12 filter grayscale" src={emocioLogo} alt="emocio"/>
                        </div>
                        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                            <img
                                className="h-12 filter grayscale"
                                src={typagLogo}
                                alt="Typ Agency"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
                            <img
                                className="h-12 filter grayscale"
                                src={dvorarLogo}
                                alt="Servis a opravy elektrospotřebičů - Dvořák opravujeme.cz"
                            />
                        </div>
                        <div className="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
                            <img
                                className="h-12 filter grayscale"
                                src={refreshLogo}
                                alt="Refresh"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
                    <div>
                        <a name="contact" />
                        <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Contact</h2>
                        <div className="mt-3">
                            <p className="text-lg text-gray-500">
                                Due to my personality type (MBTI: INTJ) please use e-mail to contact me, even if you
                                have my phone number.
                            </p>
                        </div>
                        <div className="mt-9">
                            <div className="mt-6 flex">
                                <div className="flex-shrink-0">
                                    <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                </div>
                                <div className="ml-3 text-base text-gray-500">
                                    <p>jan@skurovec.cz</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-9">
                            <div className="mt-6 flex">
                                <div className="flex-shrink-0">
                                    <DownloadIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                </div>
                                <div className="ml-3 text-base text-gray-500">
                                    <p>
                                        <a href={'Skurovec_CV.pdf'}>Curriculum vitae </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-9">
                            <div className="mt-6 flex">
                                <div className="flex-shrink-0 text-gray-500">
                                    <LocationMarkerIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                </div>
                                <div className="ml-3 text-base text-gray-500">
                                    <p>Prague, Czech Republic, EU</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 md:mt-0">
                        <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Now</h2>
                        <div className="mt-3">
                            <p className="text-lg text-gray-500">
                                Currently I’m trying to dive into Rust, both for server side and client side (WebAssembly) web apps. I also like Clojure
                            </p>
                        </div>
                        <div className="mt-9">
                            <div className="mt-6 flex">
                                <div className="flex-shrink-0 text-gray-500">
                                    Technologies
                                </div>
                                <div className="ml-3 text-base text-gray-500">
                                    <p>PHP 7.4/8 (Symfony, Doctrine)</p>
                                    <p>MySQL</p>
                                    <p>Javascript (React)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </Layout>
)

export default IndexPage
